import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/privacy',
    name: 'privacy',
    meta: { title: '隐私协议' },
    component: () => import('../views/privacy.vue')
  },
  // {
  //   path: '/bl_privacy',
  //   name: 'blPrivacy',
  //   meta: { title: '豹亮隐私协议' },
  //   component: () => import('../views/bl_privacy.vue')
  // },
  {
    path: '/user',
    name: 'user',
    meta: { title: '青点互动服务协议' },
    component: () => import('../views/user.vue')
  },
  {
    path: '/children',
    name: 'children',
    meta: { title: '儿童个人信息保护指引' },
    component: () => import('../views/children.vue')
  },
  {
    path: '/listing',
    name: 'listing',
    meta: { title: '青点互动第三方信息共享清单' },
    component: () => import('../views/listing.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title
  }
  // else {
  //   document.title = ''
  // }
  next() //执行进入路由，如果不写就不会进入目标页
})

export default router
